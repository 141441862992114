.title[data-v-d713c1d1] {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.setting[data-v-d713c1d1] {
  height: calc(var(--col-group-width) - 22px);
  line-height: calc(var(--col-group-width) - 22px);
  cursor: pointer;
}
