.show[data-v-f286d1fa] {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.root[data-v-f286d1fa],
.row[data-v-f286d1fa],
.col[data-v-f286d1fa] {
  height: max-content;
  width: max-content;
  overflow: visible;
  display: grid;
  --row-group-width: 240px;
  --col-group-width: 42px;
}
.root *[data-v-f286d1fa],
.row *[data-v-f286d1fa],
.col *[data-v-f286d1fa] {
  box-sizing: border-box;
}
.row[data-v-f286d1fa] {
  min-width: 100%;
  writing-mode: vertical-lr;
  grid-template-columns: repeat(var(--f286d1fa-rowParams\.length), auto);
  grid-template-rows: repeat(var(--f286d1fa-rowGroups\.length), var(--row-group-width)) auto;
}
.col[data-v-f286d1fa] {
  min-height: 100%;
  grid-template-columns: repeat(var(--f286d1fa-colParams\.length), 240px);
  grid-template-rows: repeat(var(--f286d1fa-colGroups\.length), var(--col-group-width)) auto;
}
.root[data-v-f286d1fa] {
  grid-template-columns: repeat(var(--f286d1fa-rowGroups\.length), var(--row-group-width)) repeat(var(--f286d1fa-colParams\.length), 240px);
  grid-template-rows: repeat(var(--f286d1fa-colGroups\.length), var(--col-group-width)) repeat(var(--f286d1fa-rowParams\.length), auto);
}
.root[data-v-f286d1fa]::before {
  content: '泳道';
  grid-row: span var(--f286d1fa-colGroups\.length);
  grid-column: span var(--f286d1fa-rowGroups\.length);
  z-index: 2;
  position: sticky;
  inset-block-start: 0;
  inset-inline-start: 0;
  border: 1px solid #EEEEEE;
  overflow: hidden;
  font-size: 14px;
  padding: 10px;
  line-height: calc(var(--col-group-width) * var(--f286d1fa-colGroups\.length) - 22px);
  background-color: var(--card-bg);
}
.header[data-v-f286d1fa] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.header h2 > a[data-v-f286d1fa] {
  float: right;
}
.add[data-v-f286d1fa] {
  flex-shrink: 0;
}
.inner[data-v-f286d1fa] {
  display: flex;
  min-block-size: 50px;
  border: 1px solid #EEEEEE;
  flex-direction: column;
  overflow: hidden;
  max-block-size: calc(var(--f286d1fa-height) - var(--f286d1fa-colGroups\.length) * var(--col-group-width));
}
.row .inner[data-v-f286d1fa] {
  max-block-size: calc(var(--f286d1fa-width) - var(--f286d1fa-colGroups\.length) * var(--col-group-width));
}
.inner[data-v-f286d1fa] > :first-child {
  border-style: dashed;
  margin: 4px;
  background-color: transparent;
  overflow: hidden;
  border-color: currentColor;
  border-radius: var(--border-radius-md);
  color: #AAAAAA;
}
.list[data-v-f286d1fa] {
  flex: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.item[data-v-f286d1fa] {
  padding: 10px;
  margin: 4px;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  background-color: var(--kanban-card-bg);
  cursor: grab;
  padding: var(--padding-sm);
}
.is-moving {
  transform: scale(1.5);
  background: rgba(0, 0, 0, 0.8);
}
.item > *[data-v-f286d1fa] {
  writing-mode: horizontal-tb;
}
.row .item[data-v-f286d1fa] {
  width: 240px;
}
