.title[data-v-5b5929e0] {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.button[data-v-5b5929e0] {
  cursor: pointer;
  height: calc(var(--col-group-width) - 22px);
  line-height: calc(var(--col-group-width) - 22px);
}
.button + .button[data-v-5b5929e0] {
  margin-left: 8px;
}
