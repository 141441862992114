.header[data-v-37b733a7] {
  border: 1px solid #EEEEEE;
  position: sticky;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
  font-size: 14px;
  padding: 10px;
  line-height: calc(var(--col-group-width) - 22px);
  inset-block-start: calc(var(--index) * var(--col-group-width));
  background-color: var(--card-bg);
  z-index: 1;
}
.column[data-v-37b733a7] {
  inset-block-start: auto;
  min-block-size: 100px;
  inset-inline-start: calc(var(--index) * var(--row-group-width));
}
