.el-button-group > .el-dropdown {
  border-radius: 0;
  border-left-color: var(--el-button-border-color);
}
.el-button-group > .el-dropdown {
  border-left-color: var(--el-button-divide-border-color);
}
.el-button-group > .el-dropdown {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.el-button-group > .el-dropdown {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
