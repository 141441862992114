.line[data-v-5fd69c16] {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  margin: 4px;
  padding: 4px;
  inline-size: 100%;
}
.line[data-v-5fd69c16]:hover {
  background: #0001;
  border-radius: 4px;
}
.label[data-v-5fd69c16] {
  flex: 1;
}
.footer[data-v-5fd69c16] {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.placeholder[data-v-5fd69c16] {
  flex: 1;
}
