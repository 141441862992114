.header[data-v-dae41b19] {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
  height: 70px;
  padding-inline: 15px;
}
.main[data-v-dae41b19] {
  height: calc(100vh - var(--navbar-height) - 75px);
  overflow: auto;
}
.main[data-v-dae41b19]:fullscreen {
  max-width: unset !important;
  background-color: var(--bg-color);
}
.title[data-v-dae41b19] {
  flex: 1;
  cursor: pointer;
  margin-bottom: 0px;
  margin-right: var(--margin-sm);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.group[data-v-dae41b19] .el-button-group > .el-dropdown > .el-button {
  border-radius: 0;
  border-left-color: var(--el-button-border-color);
}
.group[data-v-dae41b19] .el-button-group > .el-dropdown + .el-dropdown > .el-button {
  border-left-color: var(--el-button-divide-border-color);
}
.group[data-v-dae41b19] .el-button-group > .el-dropdown:first-child > .el-button.is-circle {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.group[data-v-dae41b19] .el-button-group > .el-dropdown:last-child > .el-button.is-circle {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
